// This manages access to runtime configuration as set in a separate file
// deployed with this application.

import React from 'react';
import useAxios from 'axios-hooks';
import { useTranslation } from 'react-i18next';
import { ErrorMessage } from 'components/ErrorMessage';

// Config properties.

// Regional values set in the Auth0 admin for this application.
export type RegionalAuth0 = {
  domain: string;
  clientId: string;
}

// Regional values provided for Cognito authentication flow.
export type RegionalCognito = {
  domain: string;
}

export interface ConfigData {
  auth0?: {
    regions?: {
      [region: string]: RegionalAuth0;
    };

    // Value set in the Auth0 admin for this application.
    redirectUri: string;

    // Prefix for all Blackboard-related claims in the token.
    bbNamespace: string;
  };
  cognitoSignIn?: {
    domain: string;
  };
  returnUrl?: {
    allowedHostnames: string[];
    allowedProtocols: string[];
    fallback: string;
  };
}

export interface AppConfigProps extends ConfigData {
  loading: boolean;
}

export interface AppConfigProviderProps {}

export const AppConfigContext = React.createContext<AppConfigProps>({ loading: true });

export const useAppConfigContext = () => React.useContext(AppConfigContext);

export const AppConfigProvider: React.FunctionComponent<AppConfigProviderProps> = ({ children }) => {
  const [{ data, loading, error }] = useAxios(`${process.env.PUBLIC_URL}/config/config${process.env.NODE_ENV === 'development' ? '.dev' : ''}.json`);
  const { t } = useTranslation();

  if (error) {
    return <ErrorMessage title={t('global.configError')} message={error.message} variant="fill-window" />;
  }

  let context: AppConfigProps = { loading };

  if (data) {
    context = { ...data, ...context };
  }

  return <AppConfigContext.Provider value={context}>{children}</AppConfigContext.Provider>;
};

export default AppConfigProvider;
