import * as React from 'react';
import * as ReactDOM from 'react-dom';
import { ErrorBoundary } from 'react-error-boundary';
import { i18n, I18nThemeProvider } from '@bb-ui/i18n/dist';
import { AppConfigProvider } from 'contexts/AppConfigContext';
import { App } from 'App';
import * as serviceWorker from './serviceWorker';

i18n.init({
  whitelist: ['ar', 'en'],
  ns: ['translation', 'links'],
});

/**
 * Last resort error message.
 * Keeping this simple since translations and bb-ui aren't available at this point.
 */
const ErrorFallback = ({ error }: { error?: Error }) => (
  <div role="alert">
    <h1>Uh oh. An error occurred. Please refresh the page or contact your system administrator for help.</h1>
    {error && <p>{error.message}</p>}
  </div>
);

ReactDOM.render(
  <ErrorBoundary FallbackComponent={ErrorFallback}>
    <React.Suspense fallback="">
      <I18nThemeProvider theme="dark">
        <AppConfigProvider>
          <App />
        </AppConfigProvider>
      </I18nThemeProvider>
    </React.Suspense>
  </ErrorBoundary>,
  document.getElementById('root'),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
