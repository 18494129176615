import { ErrorMessage } from 'components/ErrorMessage';
import { Layout } from 'components/Layout';
import { useSignInMethodContext } from 'contexts/SignInMethodContext/SignInMethodContext';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { AppRedirect } from './AppRedirect';

function useQuery() {
  return new URLSearchParams(window.location.search);
}

export const Callback = () => {
  const { t } = useTranslation();
  const query = useQuery();
  const { reset } = useSignInMethodContext();
  const error = query.get('error');

  // If there was an internal error authenticating the user, clear their signin
  // method so they can start over.

  React.useEffect(() => {
    if (error) {
      reset();
    }
  }, [error, reset]);

  if (error) {
    const errorDescription = query.get('error_description');

    return (
      <Layout>
        <ErrorMessage
          title={t('global.auth0Error', { error })}
          message={String(errorDescription)}
        />
      </Layout>
    );
  }

  return (
    <Layout>
      <AppRedirect />
    </Layout>
  );
};

export default Callback;
