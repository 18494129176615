import { BbThemeProvider } from '@bb-ui/react-library';
import { useTheme } from '@material-ui/core/styles';
import { Layout } from 'components/Layout';
import { LoadingIndicator } from 'components/LoadingIndicator';
import { useAppConfigContext } from 'contexts/AppConfigContext';
import { useSignInMethodContext } from 'contexts/SignInMethodContext/SignInMethodContext';
import { useDiscoverableTenant } from 'hooks/useDiscoverableTenant';
import { useReturnUrl } from 'hooks/useReturnUrl';
import { useTenantIdps } from 'hooks/useTenantIdps';
import * as React from 'react';
import { IdpPicker } from './IdpPicker';
import { StartSignIn } from './StartSignIn';
import { TenantPicker } from './TenantPicker';

export const Pickers: React.FC = () => {
  const { idp, reset, setIdp, setTenantId, tenantId } = useSignInMethodContext();
  const { tenant } = useDiscoverableTenant(tenantId);
  const { idps } = useTenantIdps(tenantId);
  const { cognitoSignIn } = useAppConfigContext();
  const theme = useTheme();

  // Save the return URL to local storage.

  const returnUrl = useReturnUrl();

  // If a tenant has been chosen that only has one IDP, set it for the user.

  React.useEffect(() => {
    if (setIdp && !idp && idps?.length === 1) {
      setIdp(idps[0]);
    }
  }, [idp, idps, setIdp]);

  // If we don't have a tenant yet, show the picker.

  if (!tenantId) {
    return (
      <Layout>
        <TenantPicker onPickTenantId={setTenantId} />
      </Layout>
    );
  }

  // We have a tenant--compute what the header should be.

  let title = tenant?.displayName ?? tenant?.name;
  let titleImageSrc: string | undefined;

  if (tenant?.logoDisplayName && tenant?.logoLink) {
    title = tenant?.logoDisplayName;
    titleImageSrc = tenant?.logoLink;
  }

  // If the user has chosen a tenant but not an IDP, show that.

  if (!idp) {
    return (
      <BbThemeProvider theme="light" isRtl={theme.direction === 'rtl'}>
        <Layout title={title} titleImageSrc={titleImageSrc}>
          <IdpPicker onPickIdp={setIdp!} onResetTenant={reset} tenantId={tenantId} />
        </Layout>
      </BbThemeProvider>
    );
  }

  // We have everything we need to begin sign in.

  if (idp.brokerType === 'Cognito' && cognitoSignIn) {
    // Redirect the user to Cognito sign-in UI to complete authentication.

    window.location.href = `${cognitoSignIn.domain}/?tenantId=${tenantId}&idpId=${idp.id}&returnUrl=${returnUrl}`;

    return <LoadingIndicator theme="light" variant="fill-window" />;
  }

  // Begin Auth0 authentication.

  return (
    <BbThemeProvider theme="light" isRtl={theme.direction === 'rtl'}>
      <Layout title={title} titleImageSrc={titleImageSrc}>
        <StartSignIn idp={idp} tenantId={tenantId} />
      </Layout>
    </BbThemeProvider>
  );
};
