import { useDiscoverableTenants } from './useDiscoverableTenants';

/**
 * Returns information about a single discoverable tenant.
 */
export function useDiscoverableTenant(tenantId?: string) {
  const { error, loading, tenants } = useDiscoverableTenants();

  if (!tenantId) {
    return { loading: true };
  }

  return {
    error,
    loading,
    tenant: tenants ?
      tenants.find((tenant) => tenant.id === tenantId) :
      undefined,
  };
}
