import {
  FormControlLabel,
  Link,
  PrimaryButton,
  Radio,
  RadioGroup,
  Typography,
} from '@bb-ui/react-library';
import { createStyles, makeStyles } from '@material-ui/core';
import { Theme } from '@material-ui/core/styles/createMuiTheme';
import classnames from 'classnames';
import { ErrorMessage } from 'components/ErrorMessage';
import { LoadingIndicator } from 'components/LoadingIndicator';
import { IdentityProvider, useTenantIdps } from 'hooks/useTenantIdps';
import { orderBy } from 'lodash';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { CustomRadioSelectedIcon } from './CustomRadioSelectedIcon';

const radioHeight = 80;
const radioGap = 12;

const useStyles = makeStyles((theme: Theme) => createStyles({
  footerLinks: {
    borderTop: `1px solid ${theme.palette.secondary.main}`,
    fontSize: '12px',
    paddingTop: theme.spacing(4),
  },
  loading: {
    textAlign: 'center',
  },
  prompt: {
    color: theme.palette.text.secondary,
    paddingBottom: theme.spacing(1),
    textAlign: 'center',
  },
  radio: {
    backgroundColor: theme.palette.background.paper,
    border: `2px solid ${theme.palette.background.b4}`,
    borderRadius: '4px',
    margin: 0,
    minHeight: radioHeight,
    paddingLeft: '25px',
    paddingRight: '25px',
  },
  radioChecked: {
    backgroundColor: '#f2f8fc',
    border: `2px solid ${theme.palette.focus.main}`,
  },
  radioGroup: {
    display: 'grid',
    gridGap: radioGap,
    maxHeight: `${3 * radioHeight + 2 * radioGap}px`,
    overflowY: 'auto',
  },
  root: {
    display: 'grid',
    gridGap: theme.spacing(3),
    [theme.breakpoints.up('sm')]: {
      width: '500px',
    },
  },
  tenantNameText: {
    paddingBottom: '30px',
    textAlign: 'center',
  },
}));

// This component does not update the global IDP immediately--the user needs to
// select one, then click Continue.

export interface IdpPickerProps {
  onPickIdp: (value: IdentityProvider) => void;
  onResetTenant: () => void;
  tenantId: string;
}

/**
 * Allows the user to choose an IDP for a tenant to use.
 */
export const IdpPicker: React.FC<IdpPickerProps> = (props) => {
  const { onPickIdp, onResetTenant, tenantId } = props;
  const { error, idps, loading } = useTenantIdps(tenantId);
  const [selectedIdpId, setSelectedIdpId] = React.useState<string>();
  const classes = useStyles(props);
  const { t } = useTranslation();

  const handleReset: React.MouseEventHandler = (e) => {
    e.preventDefault();
    onResetTenant();
  };

  const footerLinks = (
    <div className={classes.footerLinks}>
      <Link component="button" onClick={handleReset}>
        <Typography variant="body2">{t('idpPicker.changeTenant')}</Typography>
      </Link>
    </div>
  );

  // If the tenant ID has changed, reset the selected IDP.

  React.useEffect(() => {
    setSelectedIdpId(undefined);
  }, [tenantId]);

  if (loading) {
    return <LoadingIndicator data-testid="loading-idp-data" />;
  }

  if (error) {
    return (
      <>
        <ErrorMessage
          data-testid="error-loading-idp-data"
          title={t('global.fetchError')}
          message={error.message}
        />
        {footerLinks}
      </>
    );
  }

  if (idps?.length === 0) {
    return (
      <>
        <ErrorMessage
          data-testid="error-loading-idp-data"
          title={t('idpPicker.noIdpData')}
        />
        {footerLinks}
      </>
    );
  }

  const handleSubmit: React.FormEventHandler = (e) => {
    e.preventDefault();

    if (!selectedIdpId) {
      throw new Error('No IDP is selected');
    }

    onPickIdp(idps!.find(idp => idp.id === selectedIdpId)!);
  };

  return (
    <form onSubmit={handleSubmit} className={classes.root}>
      <Typography variant="h2" className={classes.prompt}>
        {t('idpPicker.prompt')}
      </Typography>
      <RadioGroup
        aria-label={t('idpPicker.prompt')}
        name="login-connection"
        onChange={(e) => setSelectedIdpId(e.target.value)}
        className={classes.radioGroup}
      >
        {orderBy(idps, 'displayName').map((idp) => (
          <FormControlLabel
            value={idp.id}
            checked={selectedIdpId === idp.id}
            control={<Radio checkedIcon={<CustomRadioSelectedIcon />} />}
            key={idp.id}
            label={t('idpPicker.optionLabel', {
              connectionName: idp.displayName,
            })}
            className={classnames(classes.radio, {
              [classes.radioChecked]: selectedIdpId === idp.id,
            })}
          />
        ))}
      </RadioGroup>
      <PrimaryButton
        disabled={selectedIdpId === undefined}
        type="submit"
        data-testid="idp-picker-submit"
      >
        {t('idpPicker.continue')}
      </PrimaryButton>
      {footerLinks}
    </form>
  );
};
