import { Callback } from 'pages/Callback/Callback';
import { Pickers } from 'pages/Pickers/Pickers';
import * as React from 'react';
import { MemoryRouter, Redirect, Route, Switch } from 'react-router-dom';

export const Routes = () => (
  <Switch>
    <Route exact path="/"><Redirect to="/pickers" /></Route>
    <Route path="/pickers"><Pickers /></Route>
    <Route path="/callback"><Callback /></Route>
  </Switch>
);

export const RoutesWithMemoryRouter = () => {
  const initialIndex = window.location.pathname === '/callback' ? 1 : 0;

  return (
    <MemoryRouter initialEntries={['/', '/callback']} initialIndex={initialIndex}>
      <Routes />
    </MemoryRouter>
  );
};

export default RoutesWithMemoryRouter;
