import { LoadingIndicator } from 'components/LoadingIndicator';
import { useAppConfigContext } from 'contexts/AppConfigContext';
import { SignInMethodProvider } from 'contexts/SignInMethodContext/SignInMethodContext';
import AuthProvider from 'contexts/AuthProvider/AuthProvider';
import * as React from 'react';
import { Routes } from 'Routes';

export const App = () => {
  const { loading } = useAppConfigContext();

  if (loading) {
    return <LoadingIndicator variant="fill-window" />;
  }

  return (
    <SignInMethodProvider>
      <AuthProvider>
        <Routes />
      </AuthProvider>
    </SignInMethodProvider>
  );
};
