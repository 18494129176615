import * as React from 'react';
import classnames from 'classnames';
import { makeStyles, createStyles } from '@material-ui/core';
import { TextField } from '@bb-ui/react-library/dist/components/TextField';
import { Typography } from '@bb-ui/react-library/dist/components/Typography';
import { Props as SelectProps } from 'react-select';
import { InputAdornment } from '@bb-ui/react-library/dist/components/InputAdornment';
import { Search } from '@bb-ui/icons/dist/small/Search';
import { MenuItem } from '@bb-ui/react-library/dist/components/MenuItem';
import { inputComponent, HighlightString } from '@bb-ui/react-library/dist/components/Picker/PickerComponents';
// Needed for commented out piece of Option component
// import { announceOptionFocus } from '@bb-ui/react-library/dist/components/Picker/PickerAccessibility';
// import { OptionType } from '@bb-ui/react-library/dist/components/Picker/Picker.types';

export const useStyles = makeStyles(() => createStyles({
  control: {
    position: 'relative',
  },
  input: {
    paddingLeft: '40px',
  },
  placeholder: {
    marginLeft: '30px',
  },
  hideOffScreen: {
    position: 'absolute' as 'absolute',
    overflow: 'hidden',
    width: '1px',
    height: '1px',
    margin: '-1px',
    padding: 0,
    border: 0,
    clip: 'rect(0 0 0 0)',
  },
  menuItem: {
    '&:hover': {
      textDecoration: 'underline',
    },
  },
  menuItemFocus: {
    textDecoration: 'underline',
  },
}));

/*
 * Provide the Control component to include the search icon.
 * Left padding is needed to prevent children components from overlapping the icon.
 * This padding also shifted the original menu anchor ref element so a
 * wrapping div has been added to serve as the new menu achor (containerRef).
 */

export const Control = (props: SelectProps) => {
  const { classes: providedClasses, floatingLabel, inputId, labelId, isDisabled, label, textFieldProps, containerRef } = props.selectProps;
  const { children, innerProps, isFocused, hasValue } = props;
  const classes = useStyles(props);

  return (
    <div className={classes.control} ref={containerRef}>
      <TextField
        fullWidth
        disabled={isDisabled}
        label={label}
        floatingLabel={floatingLabel}
        InputProps={{
          inputComponent,
          startAdornment: (
            <InputAdornment position="start">
              <Search />
            </InputAdornment>
          ),
          inputProps: {
            children,
            className: classnames(providedClasses.input, classes.input),
            ...innerProps,
          },
        }}
        InputLabelProps={{
          className: classes.hideOffScreen,
          htmlFor: inputId,
          id: labelId,
          shrink: floatingLabel && (isFocused || hasValue),
        }}
        {...textFieldProps}
      />
    </div>
  );
};

/*
 * Provide the Placeholder component since we don't currently have a means of styling it externally.
 * Placeholder is external to Control and needs its own margin to avoid overlapping the icon.
 */

export const Placeholder = (props: SelectProps) => {
  const { classes: providedClasses, floatingLabel } = props.selectProps;
  const { children, innerProps, isFocused } = props;
  const classes = useStyles(props);

  return (
    <Typography
      color="textSecondary"
      className={
        classnames(
          {
            [providedClasses.floatingLabel]: floatingLabel,
            [providedClasses.inputFocused]: isFocused,
          },
          providedClasses.placeholder,
          classes.placeholder,
        )
      }
      {...innerProps}
    >
      <span>{children}</span>
    </Typography>
  );
};

/*
 * Provide the Option component.
 * See internal TODO below for more info.
 */

export const Option = (props: SelectProps) => {
  const { classes: providedClasses, highlightFilteredMatches } = props.selectProps; // setAriaLiveMessage removed
  const { children, innerRef, innerProps, isFocused, isSelected } = props; // options, data removed
  const classes = useStyles(props);

  /*
   * Note about props.data['__isNew__'] (only applies to creatable option):
   * Due to this causing a rerender and react-select reverting the focused option to the previously focused
   * option as opposed to maintaining focus on the creatable option, we're bypassing setting the message to
   * prevent the rerender. This is a temporary solution so that we can maintains keyboard functionality for
   * the creatable option.
   *
   * This will be revisited when https://github.com/JedWatson/react-select/pull/3358 is approved and we can
   * remove our aria live region handling in favor of react-select's built in handling.
   */

  // eslint-disable-next-line no-underscore-dangle
  // if (isFocused && !data.__isNew__) {
  //   /* TODO: resolve warning in bb-ui
  //    * Warning: Cannot update a component (`Picker`) while rendering a different component (`Option`). To locate the bad setState() call inside `Option`
  //    * TODO: this appears to less helpful when enabled (removes instructions). Only enable for creatable?
  //    * When enabled: "Option Blackboard Corporate Tenant focused, 2 0f 6."
  //    * When disabled: "Option Blackboard Corporate Tenant focused, 2 0f 6.
  //    *                 2 results available for search term b.
  //    *                 Use Up and Down to choose options, press Enter to select currently focused option,
  //    *                 press Escape to exit the menu."
  //    */
  //   setAriaLiveMessage(announceOptionFocus(props.selectProps, data.label, options as OptionType[]));
  // }

  return (
    <MenuItem
      button
      role="option"
      selected={isSelected}
      aria-selected={isFocused}
      component="div"
      className={
        classnames(
          classes.menuItem,
          {
            [classes.menuItemFocus]: isFocused,
            [providedClasses.menuItemFocus]: isFocused,
            [providedClasses.menuItemSelected]: isSelected,
          },
        )
      }
      innerRef={innerRef}
      {...innerProps}
    >
      {highlightFilteredMatches ? <HighlightString {...props} /> : children}
    </MenuItem>
  );
};

/*
 * Provide the IndicatorsContainer as a fragment since there isn't a means of disabling it through props
 */

export const IndicatorsContainer = () => <></>;
