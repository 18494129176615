import React from 'react';
import { makeStyles, createStyles } from '@material-ui/core';
import { Theme } from '@material-ui/core/styles/createMuiTheme';
import { Typography, ITypographyProps } from '@bb-ui/react-library/dist/components/Typography';

export const useStyles = makeStyles((theme: Theme) => createStyles({
  pageHeading: {
    marginBottom: theme.spacing(3),
    textAlign: 'center',
    [theme.breakpoints.down('xs')]: {
      fontSize: theme.typography.fontSizeMedium,
    },
    [theme.breakpoints.up('sm')]: {
      marginBottom: theme.spacing(4),
    },
  },
}));

export const PageHeading: React.FunctionComponent<ITypographyProps> = (props) => {
  const classes = useStyles(props);

  return <Typography className={classes.pageHeading} variant="h2">{props.children}</Typography>;
};

export default PageHeading;
