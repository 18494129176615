// Set the return URL based on these rules. The first that applies takes precedence:
// 1. `returnUrl` is set in the URL query, if it passes the whitelist
// 2. `return-url` set in local storage, if it passes the whitelist
// 3. `returnUrl` in the application configuration, disregarding whitelist

import { ConfigData, useAppConfigContext } from 'contexts/AppConfigContext';

function useQuery() {
  return new URLSearchParams(window.location.search);
}

/**
 * Returns whether a URL is a valid return URL.
 */
function verifyUrl(
  urlString: string,
  returnUrlConfig: ConfigData['returnUrl'],
) {
  const { allowedHostnames, allowedProtocols } = returnUrlConfig!;
  const url = new URL(urlString);

  if (!allowedProtocols.includes(url.protocol)) {
    return false;
  }

  return allowedHostnames.some(hostname => url.hostname.match(new RegExp(hostname)));
}

/**
 * Retrieves the return URL to use for the current user. It uses the first rule
 * in the following list whose criteria is met:
 *
 * 1. `returnUrl` is set in the URL query, if it passes the whitelist
 * 2. `return-url` set in local storage, if it passes the whitelist
 * 3. `returnUrl` in the application configuration, disregarding whitelist
 *
 * If scenario 1 occurs, this hook also saves the query string to the local
 * storage key `return-url`.
 */
export function useReturnUrl() {
  const { returnUrl: configReturnUrl } = useAppConfigContext();
  const returnUrlFromStorage =
    window.localStorage.getItem('return-url') ?? undefined;
  const query = useQuery();
  const returnUrlFromQueryString = query.get('returnUrl');
  let returnUrl = configReturnUrl!.fallback;

  if (
    returnUrlFromQueryString &&
    verifyUrl(returnUrlFromQueryString, configReturnUrl)
  ) {
    returnUrl = returnUrlFromQueryString;
    window.localStorage.setItem('return-url', returnUrl);
  } else if (
    returnUrlFromStorage &&
    verifyUrl(returnUrlFromStorage, configReturnUrl)
  ) {
    returnUrl = returnUrlFromStorage;
  }

  return returnUrl;
}
