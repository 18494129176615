import { UASProvider } from '@bb-ui/auth/dist/auth0/UASProvider';
import { ErrorMessage } from 'components/ErrorMessage';
import { LoadingIndicator } from 'components/LoadingIndicator';
import { useAppConfigContext } from 'contexts/AppConfigContext';
import { useSignInMethodContext } from 'contexts/SignInMethodContext/SignInMethodContext';
import { useDiscoverableTenant } from 'hooks/useDiscoverableTenant';
import React from 'react';
import { useTranslation } from 'react-i18next';

/**
 * Creates an Auth0 context based on the tenant the user has chosen. This
 * doesn't handle sending IDP during a login() call--use useIdpLogin for that.
 */
export const AuthProvider: React.FC = (props) => {
  const { children } = props;
  const { loading, auth0 } = useAppConfigContext();
  const { tenantId } = useSignInMethodContext();
  const { tenant } = useDiscoverableTenant(tenantId);
  const { t } = useTranslation();

  if (!tenant) {
    return <>{children}</>;
  }

  if (loading) {
    return (
      <LoadingIndicator
        variant="fill-window"
        data-testid="loading-app-config"
      />
    );
  }

  // No Auth0 config, period.

  if (!auth0) {
    return (
      <ErrorMessage
        data-testid="error-no-auth0-config"
        title={t('global.configError')}
      />
    );
  }

  const regionalAuth = auth0.regions?.[tenant.region];

  // No auth0 config for the tenant's region.

  if (!regionalAuth) {
    return (
      <ErrorMessage
        data-testid="error-no-auth0-config-for-region"
        title={t('authProvider.noRegionalData', { region: tenant.region })}
      />
    );
  }

  return (
    <UASProvider
      apiBaseURL="/sso/api/v1"
      audienceNamespace={`${auth0!.bbNamespace}/tenants`}
      auth0Clients={{
        [tenant.region]: {
          domain: regionalAuth.domain,
          client_id: regionalAuth.clientId,
        }
      }}
      redirect_uri={auth0!.redirectUri}
      tenantId={tenant.id}
      tenantUriComponent={tenant.uriComponent}
    >
      {children}
    </UASProvider>
  );
};

export default AuthProvider;
