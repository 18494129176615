import useAxios from "axios-hooks";
import { apiUrl } from "utils/apiUrl";

export interface IdentityProvider {
  brokerType: 'Auth0' | 'Cognito';
  changePasswordLink?: string;
  displayName: string;
  id: string;
  name: string;
  description: string;
  type: 'LearnConnector' | 'ldap' | 'SAML';
}

interface IdentityProvidersResponse {
  results: IdentityProvider[];
}

// TODO: handle Learn hostname? Unclear how that would be sent, or if this is
// applicable to our use case--would they go directly to the auth broker instead?

/**
 * Returns identity providers associated with a tenant.
 */
export function useTenantIdps(tenantId?: string) {
  const [{ data, error, loading }] = useAxios<IdentityProvidersResponse>(apiUrl('sso', `tenants/${tenantId}/identityProviders`));

  if (!tenantId) {
    return { loading: true };
  }

  let idps: IdentityProvider[] | undefined;

  if (data) {
    idps = data.results ?? [];
  }

  return { error, idps, loading };
}
