import { Typography } from '@bb-ui/react-library/dist/components/Typography';
import { createStyles, makeStyles } from '@material-ui/core';
import { ErrorMessage } from 'components/ErrorMessage';
import { LoadingIndicator } from 'components/LoadingIndicator';
import { PageHeading } from 'components/PageHeading';
import { PickerI18n } from 'components/PickerI18n';
import {
  Control,
  IndicatorsContainer,
  Option,
  Placeholder,
} from 'components/PickerI18n/PickerComponents';
import { useDiscoverableTenants } from 'hooks/useDiscoverableTenants';
import * as React from 'react';
import { useTranslation } from 'react-i18next';

interface TenantOption {
  label: string;
  value: string;
}

export const useStyles = makeStyles(() => createStyles({
  picker: {
    width: '100%',
  },
}));

export interface TenantPickerProps {
  onPickTenantId: (value: string) => void;
}

/**
 * Allows the user to choose a tenant to sign into.
 */
export const TenantPicker: React.FC<TenantPickerProps> = (props) => {
  const { onPickTenantId } = props;
  const classes = useStyles(props);
  const { t } = useTranslation();
  const { error, loading, tenants } = useDiscoverableTenants();
  const [inputValue, setInputValue] = React.useState('');
  const inputValueOnBlur = React.useRef('');

  const pickerOptions = React.useMemo(
    () => (tenants ?
      tenants.map((tenant) => ({
        label: tenant.displayName ?? tenant.name,
        value: tenant.id,
      })) : []),
    [tenants],
  );

  // react-select clears the input on blur. We want to preserve the input value,
  // so we store it on blur to set after being cleared.

  React.useEffect(
    () => {
      if (!inputValue && inputValueOnBlur.current) {
        setInputValue(inputValueOnBlur.current);
        inputValueOnBlur.current = '';
      }
    },
    [inputValue],
  );

  function handleOnBlur() {
    inputValueOnBlur.current = inputValue;
  }

  if (loading) {
    return <LoadingIndicator data-testid="loading-tenant-data" />;
  }

  if (error) {
    return (
      <ErrorMessage
        data-testid="error-loading-tenant-data"
        title={t('global.fetchError')}
        message={error.message}
      />
    );
  }

  if (tenants.length === 0) {
    return (
      <Typography paragraph data-testid="no-tenant-data">
        {t('tenantPicker.noTenantData')}
      </Typography>
    );
  }

  return (
    <>
      <PageHeading>{t('tenantPicker.findYourTenant')}</PageHeading>
      <PickerI18n
        className={classes.picker}
        components={{ Control, Placeholder, IndicatorsContainer, Option }}
        showOnlyFilteredResults
        highlightFilteredMatches={false}
        options={pickerOptions}
        onChange={(option) => onPickTenantId((option as TenantOption).value)}
        inputId="select-tenant"
        label={t('tenantPicker.selectTenant')}
        placeholder={t('tenantPicker.selectTenant')}
        noOptionsMessage={({ inputValue }) => t('tenantPicker.noTenantFound', { inputValue })}
        openMenuOnFocus={Boolean(inputValue)}
        openMenuOnClick={Boolean(inputValue)}
        onInputChange={setInputValue}
        inputValue={inputValue}
        onBlur={handleOnBlur}
        tabSelectsValue={false}
      />
    </>
  );
};

export default TenantPicker;
