import * as React from 'react';
import classnames from 'classnames';
import { makeStyles, createStyles } from '@material-ui/core';
import { Theme } from '@material-ui/core/styles/createMuiTheme';
import { Typography } from '@bb-ui/react-library/dist/components/Typography';
import { Link } from '@bb-ui/react-library/dist/components/Link';
import { useTranslation } from 'react-i18next';
import { Logo } from 'components/Logo';
import { Divider } from '@bb-ui/react-library';

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    footer: {
      display: 'flex',
      alignItems: 'start',
      padding: theme.spacing(4, 0, 0),
      minHeight: '122px',
      flexWrap: 'wrap',
      [theme.breakpoints.up('md')]: {
        alignItems: 'center',
        padding: theme.spacing(0),
        minHeight: '100px',
      },
    },
    footerPrimary: {
      display: 'flex',
      alignItems: 'center',
      flexWrap: 'wrap',
      marginRight: theme.spacing(3),
    },
    footerPrimaryItem: {
      marginRight: theme.spacing(3),
      marginBottom: theme.spacing(2),
      [theme.breakpoints.up('md')]: {
        marginBottom: 0,
      },
    },
    copyright: {
      flex: '0 0 100%',
      [theme.breakpoints.up('md')]: {
        flex: 'auto',
      },
    },
    links: {
      display: 'inline-flex',
      alignItems: 'center',
      marginLeft: 'auto',
      fontWeight: theme.typography.fontWeightSemiBold,
      lineHeight: 1.6,
    },
    logo: {
      height: '20px',
      width: 'auto',
      paddingRight: theme.spacing(3),
    },
  }),
);

export const Footer: React.FunctionComponent = (props) => {
  const classes = useStyles(props);
  const { t } = useTranslation(['translation', 'links']);

  return (
    <>
      <Divider />
      <footer className={classes.footer}>
        <div className={classes.footerPrimary}>
          <Logo className={classes.logo} data-testid="anthology-logo" />
          <Typography className={classnames(classes.footerPrimaryItem, classes.copyright)}>
            ©{new Date().getFullYear()} {t('footer.copyright')}
          </Typography>
        </div>
        <div className={classes.links}>
          <Link href={t('links:terms')} className={classes.footerPrimaryItem} color="inherit">
            {t('footer.terms')}
          </Link>
          <Link
            href={t('links:privacyPolicy')}
            className={classes.footerPrimaryItem}
            color="inherit"
          >
            {t('footer.privacy')}
          </Link>
          <Link href={t('links:help')} className={classes.footerPrimaryItem} color="inherit">
            {t('footer.help')}
          </Link>
        </div>
      </footer>
    </>
  );
};

export default Footer;
