// Holds state for the tenant and IDP the user has chosen to sign in with. This
// is also persisted to local storage so that:
//
// - After authenticating and the user is returned to the /callback route, we
//   know which tenant (aka audience to use) to initiate the session with.
//
// If ?reset is in the URL querystring, this will reset both tenant and IDP.
// This is so that users can reset their sign in method from the actual sign in
// app (Auth0 or Cognito).

import { IdentityProvider } from 'hooks/useTenantIdps';
import * as React from 'react';

export interface SignInMethodProps {
  reset: () => void;
  setTenantId: (value: string) => void;
  tenantId?: string;
  setIdp?: (value: IdentityProvider) => void;
  idp?: IdentityProvider;
}

export const SignInMethodContext = React.createContext<SignInMethodProps>({
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  reset: () => {},
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  setTenantId: () => {},
});
export const useSignInMethodContext = () => React.useContext(SignInMethodContext);

export const SignInMethodProvider: React.FC = ({ children }) => {
  const [idp, setIdp] = React.useState<IdentityProvider>();
  const [tenantId, setTenantId] = React.useState<string | undefined>(
    window.localStorage.getItem('auth-tenant-id') ?? undefined,
  );

  const wrappedSetTenantId = React.useCallback((value: string | undefined) => {
    setTenantId(value);

    if (value) {
      window.localStorage.setItem('auth-tenant-id', value);
    } else {
      window.localStorage.removeItem('auth-tenant-id');
    }
  }, []);

  const reset = React.useCallback(() => {
    wrappedSetTenantId(undefined);
  }, [wrappedSetTenantId]);

  // Reset if requested in the URL query string.

  React.useEffect(() => {
    const queryParams = new URLSearchParams(window.location.search);

    if (queryParams.has('reset')) {
      queryParams.delete('reset');
      window.location.search = queryParams.toString();
      reset();
    }
  }, [reset]);

  const context: SignInMethodProps = { reset, tenantId, setTenantId: wrappedSetTenantId };

  // Only when a tenantId is set can IDPs be interacted with.

  if (tenantId) {
    context.idp = idp;
    context.setIdp = setIdp;
  }

  return (
    <SignInMethodContext.Provider value={context}>
      {children}
    </SignInMethodContext.Provider>
  );
};
