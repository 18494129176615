import useAxios from "axios-hooks";
import { apiUrl } from "utils/apiUrl";

export interface DiscoverableTenant {
  authProviderId: string;
  displayName?: string;
  externalId: string;
  id: string;
  logoDisplayName?: string;
  logoLink?: string;
  name: string;
  description: string;
  region: string;
  uriComponent?: string;
}

/**
 * Returns information about all discoverable tenants.
 */
export function useDiscoverableTenants() {
  const [{ data, error, loading }] = useAxios<DiscoverableTenant[]>(apiUrl('tenancy', 'tenants/discoverable'));

  // Filter out tenants without regions. They're only present on non-prod tiers.

  return { error, loading, tenants: data ? data.filter(tenant => tenant.region) : data };
}
