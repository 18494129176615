import { useAuth0Context } from '@bb-ui/auth/dist/auth0/Auth0Context';
import { LoadingIndicator } from 'components/LoadingIndicator';
import { IdentityProvider } from 'hooks/useTenantIdps';
import * as React from 'react';

export interface StartSignInProps {
  idp: IdentityProvider;
  tenantId: string;
}

/**
 * Begins the sign in process for the tenant and IDP, handing off control to the auth broker.
 */
export const StartSignIn: React.FC<StartSignInProps> = ({
  idp,
  tenantId,
}) => {
  const { login } = useAuth0Context();
  const [loggingIn, setLoggingIn] = React.useState(false);

  React.useEffect(() => {
    // We need to send custom bb_login_params because @bb-ui/auth doesn't
    // support IDP ID (yet).
    //
    // We may also want to use the ?tenantIdMismatch param here as well if we
    // end up needing it. The scenario where we might need it is if the user is
    // already logged into Auth0 using another tenant, which will cause the
    // Auth0 claims injection rule to error out when it tries to retrieve claims
    // from the auth gateway aggregator service.
    //
    // See
    // https://github.com/blackboard-innersource/bb-ui/blob/master/packages/auth/src/UASProvider.tsx
    // to see how bb_login_params are normally computed.

    if (login && !loggingIn) {
      setLoggingIn(true);
      login({
        bb_login_params: window.btoa(
          JSON.stringify({
            idpId: idp.id,
            tenantId,
          }),
        ),
        appState: {
          brokerType: idp.brokerType,
        },
      });
    }
  }, [idp, loggingIn, login, tenantId]);

  return <LoadingIndicator theme="light" data-testid="loading-sign-in" />;
};
