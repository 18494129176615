import * as React from 'react';
import classnames from 'classnames';
import { makeStyles, createStyles } from '@material-ui/core';
import { Theme } from '@material-ui/core/styles/createMuiTheme';
import { BbThemeProvider } from '@bb-ui/react-library/dist/components/BbThemeProvider';
import { CircularProgress } from '@bb-ui/react-library/dist/components/Progress';
import { getColor } from '@bb-ui/react-library/dist/components/styles';

// TODO: add to component library
import Fade from '@material-ui/core/Fade';

export interface LoadingIndicatorProps {
  isLoading?: boolean;
  theme?: 'light' | 'dark';
  variant?: 'fill-window' | 'fill-content';
}

export const useStyles = makeStyles((theme: Theme) => createStyles({
  root: {
    width: '100%',
    height: '100%',
    display: 'grid',
    justifyContent: 'center',
    alignContent: 'center',
  },
  fillWindow: (props: LoadingIndicatorProps) => ({
    position: 'fixed' as 'fixed',
    zIndex: 99999,
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: props.theme ? getColor('background.default', props.theme) : theme.palette.common.black,
  }),
  loadingIndicator: (props: LoadingIndicatorProps) => ({
    color: getColor('brand.main', props.theme || 'dark'),
  }),
}));

export const LoadingIndicator = (props: LoadingIndicatorProps) => {
  const classes = useStyles(props);
  const { isLoading = true, theme, variant, ...other } = props;

  return (
    <Fade
      mountOnEnter
      unmountOnExit
      in={isLoading}
      timeout={{ enter: 0, exit: 200 }}
    >
      <BbThemeProvider theme={theme ?? 'dark'}>
        <div
          className={classnames(
            classes.root,
            {
              [classes.fillWindow]: variant === 'fill-window',
            },
          )}
          data-testid="loading-indicator"
          {...other}
        >
          <CircularProgress className={classes.loadingIndicator} />
        </div>
      </BbThemeProvider>
    </Fade>
  );
};

export default LoadingIndicator;
