import { useAuth0Context } from '@bb-ui/auth/dist/auth0/Auth0Context';
import { Link } from '@bb-ui/react-library/dist/components/Link';
import { LoadingIndicator } from 'components/LoadingIndicator';
import { PageHeading } from 'components/PageHeading';
import { useSignInMethodContext } from 'contexts/SignInMethodContext/SignInMethodContext';
import { useDiscoverableTenant } from 'hooks/useDiscoverableTenant';
import { useReturnUrl } from 'hooks/useReturnUrl';
import * as React from 'react';
import { useTranslation } from 'react-i18next';

export const AppRedirect = () => {
  const returnUrl = useReturnUrl();
  const { isAuthenticated, appState } = useAuth0Context();
  const { reset, tenantId } = useSignInMethodContext();
  const { tenant } = useDiscoverableTenant(tenantId);
  const redirectUrl = React.useMemo(() => {
    if (isAuthenticated && returnUrl && tenant) {
      return `${returnUrl}?tenantId=${tenant?.id}&tenantUriComponent=${tenant?.uriComponent}&brokerType=${appState?.brokerType}`;
    }

    return undefined;
  }, [isAuthenticated, returnUrl, tenant, appState]);
  const ready = isAuthenticated && returnUrl && tenant && redirectUrl;

  React.useEffect(() => {
    // The user has successfully logged in. Clear their selected tenant since
    // we're done working with authentication here.
    //
    // This has the side effect of forcing the user to re-select their tenant if
    // they're sent to this app again. This is unavoidable for now--otherwise
    // they would be locked into this tenant even after signing out (because
    // sign out occurs in consumer apps, not here).
    //
    // Notes on a possible future solution (that needs to be POC'd):
    // https://miro.com/app/board/uXjVOO31hmE=/?invite_link_id=968963131228
    // password: auth0test

    if (ready) {
      reset();
    }
  }, [ready, reset]);

  const { t } = useTranslation();

  if (!ready) {
    return <LoadingIndicator />;
  }

  window.location.href = redirectUrl!;

  return (
    <>
      <PageHeading>{t('appRedirect.notRedirected')}</PageHeading>
      <Link href={redirectUrl} color="inherit">{t('appRedirect.continueToUrl', { returnUrl })}</Link>
    </>
  );
};

export default AppRedirect;
